import PropTypes from "prop-types";
import { Helmet, HelmetProvider } from "react-helmet-async";

const SEO = ({ title, titleTemplate, description, keywords }) => {
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    {title} | {titleTemplate}
                </title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
            </Helmet>
        </HelmetProvider>
    );
};

SEO.propTypes = {
    title: PropTypes.string,
    titleTemplate: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.string,
}

SEO.defaultProps = {
    title: "VARI",
    titleTemplate: "Product Page",
    description: "Product page of flone react minimalist eCommerce template.",
    keywords: "service",
};

export default SEO;